import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Satire" link="http://formal.cs.utah.edu:8080/satire/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This tool gives rigorous bounds on the first order error of straightline programs. Try out the web interface. Download the tool from github for extensive usage.
    </ProjectCard>
    <ProjectCard title="Mobile Flashcards" link="https://github.com/tanmaytirpankar/mobile-flashcards" mdxType="ProjectCard">
This is an android application for flashcards developed using react-native. You can create decks, flashcards and quiz yourself. Download the expo application and follow steps on the github to use it.
    </ProjectCard>
    <ProjectCard title="Would you rather" link="https://github.com/tanmaytirpankar/would_you_rather" mdxType="ProjectCard">
This is an online web application of the popular game "Would you rather" developed using react-redux. You can use the github instructions to try it out if you are familiar with npm/yarn and react.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      