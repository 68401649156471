import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3> Get in Touch </h3>
    <p><a parentName="p" {...{
        "href": "mailto:tirpankartanmay@gmail.com"
      }}>{`tirpankartanmay@gmail.com`}</a></p>
    <a href="https://linkedin.com/in/tanmaytirpankar">LinkedIn</a>
    <br />
    <a href="https://github.com/tanmaytirpankar">Github</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      