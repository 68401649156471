import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <a href="https://linkedin.com/in/tanmaytirpankar">Resume</a>
    <p>{`I am a software developer currently completing my Masters in Computer Science at the University of Utah.
Here, I am also employed as a Research Assistant  working with Dr. Ganesh Gopalakrishnan on verification of concurrent programs.
I am also maintaining this really awesome tool called Satire developed by my peer Dr. Arnab Das which is used for calculating first order error in programs and determining worst case bounds on the output.`}</p>
    <p>{`My academic interests include learning new languages and technologies.
As such, I am always looking to start new projects using the stuff I have learned.
See the Projects section for an overview.
If you would like to collaborate on any project with me, please reach out.`}</p>
    <p>{`Apart from my academic work, I am an avid movie enthusiast and love binging online series. Science fiction and Thrillers are amongst my favorites.
I am always on the lookout for anything new to try out. I love to play all manneer of games most of which includes video games. Some of my favorites are God of War, Devil May Cry, Uncharted.
I come from a city called Pune located in West India. Having grown up in a country with a wide palette of food, I enjoy cooking and trying out new restaurants.
I know 3 languages, English, Hindi and Marathi. I am in the proceess of adding Japanese as my fourth language after which I plan to learn Spanish.`}</p>
    <p>{`I love meeting people and talk about common interests.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      